<template>
    <iframe src=" https://ggl.h5.xeknow.com/sl/25DF3W" style="width: 100%; height: 100%; " scrolling="no" allowFullScreen></iframe>
</template>
<script>
import router from '../../router';
import { Field, Button, Toast } from 'vant';
import Vue from 'vue';
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
    components: {},
    data() {
        return {
            qid: '2022081814400369237106',
            isSubmited: false,
            isMobile: false,
            isLogin: false,
            liveId: '',
            liveUrl: ''
        };
    },

    created() {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
        this.liveId = this.$route.query.liveId;
        this.liveUrl = `https://sv.shulan.com/watch/${this.liveId}?inset`;
        // if(this.$route.query.sourceID) {
        //   localStorage.setItem("tuid", this.$route.query.sourceID);
        //   alert('11')
        //   this.$router.replace({
        //     path: "/activity-infected/live",
        //     query: {
        //       liveId: this.liveId,
        //     },
        //   });
        // }
    },
    mounted() {
        console.log('route data');
        this.checkLoginState();
    },
    beforeDestroy() {
        clearTimeout(this.heartBeat);
        this.exit();
    },
    methods: {
        checkLoginState() {
            this.isLogin = localStorage.getItem('tuid') && localStorage.getItem('tuid') != 0;
            if (this.isLogin) {
                this.visitRecord();
                this.checkSubmitState();
            }
        },
        gotoQus() {
            this.$router.push({
                // path: "/activity-infected/questionnaire",
                name: 'questionnaire',
                params: {
                    visitId: this.visitId
                }
            });
        },
        gotoThirdLogin() {
            this.$router.push({
                // path: "/activity-infected/questionnaire",
                name: 'infectedLogin',
                params: {
                    visitId: this.visitId
                }
            });
            return;
        },
        visitRecord() {
            this.$axios
                .post(`/audio/video/visit/record`, {
                    userId: localStorage.getItem('tuid'),
                    userSource: 'infection-meeting',
                    roomId: this.liveId
                })
                .then(res => {
                    console.log('res', res);
                    this.visitId = res.data;
                    this.heartBeat = setTimeout(() => {
                        this.heartBeatRecord();
                    }, 5000);
                })
                .catch(() => {});
        },
        checkSubmitState() {
            this.$axios
                .get(`/questionnaire/record/info/byQuestionnaireIdAndUser?userId=${localStorage.getItem('tuid')}&userSource=infection-meeting&questionnaireId=${this.qid}`)
                .then(res => {
                    if (res.data.questionnaireRecordId) {
                        this.isSubmited = true;
                    } else {
                    }
                })
                .catch(() => {
                    Toast.clear();
                });
        },
        heartBeatRecord() {
            return;
            this.$axios
                .post(`/audio/video/heartBeat/handle`, {
                    visitId: this.visitId,
                    type: 'WATCHING'
                })
                .then(res => {
                    this.heartBeat = setTimeout(() => {
                        this.heartBeatRecord();
                    }, 5000);
                })
                .catch(() => {
                    this.heartBeat = setTimeout(() => {
                        this.heartBeatRecord();
                    }, 5000);
                });
        },
        exit() {
            return;
            if (!this.isLogin) {
                return;
            }
            this.$axios
                .post(`/audio/video/heartBeat/handle`, {
                    visitId: this.visitId,
                    type: 'WATCH_END'
                })
                .then(res => {})
                .catch(() => {});
        }
    }
};
</script>

<style lang="scss">
@function fit($num) {
    @return $num / 3;
}
#app {
    height: 100%;
}
.main2 {
    background: rgb(24, 24, 24);
    width: 100%;
    height: 100%;
}
.status-bar {
    background: #29a3dc;
    width: 100%;
    height: fit(80px);
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: fit(30px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: fit(34px);
        height: fit(34px);
        margin-left: fit(8px);
    }
}

.status-bar-mobile {
    background: #12beae;
    width: 100%;
    height: 88px;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 34px;
        height: 34px;
        margin-left: 8px;
    }
}
.mobile-iframe {
    margin-top: 88px;
}
</style>
